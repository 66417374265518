import React, {useContext, useRef, useState} from 'react'
import {Alert, Button, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import FileTemplateTable from "./FileTemplateTable";
import FileTemplateAddDrawer from "./FileTemplateAddDrawer";
import PageHeader from "../../misc/PageHeader";
import SiteTable from "../Sites/SiteTable";

const FileTemplatesPage = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const FileTemplateAddRef = useRef();

    // React Query
    const { data: Templates, isLoading: isLoading, refetch: refresh } = useQuery(
        ["filetemplate"],
        async() => await API.getFileTemplates()
    );

    // State
    const [FileTemplateAddVisible,SetFileTemplateAddVisible] = useState(false);

    return (
        <>
            <PageHeader
                Title="File Templates"
                Buttons={[
                    <Button icon={<PlusCircleOutlined />} onClick={() => FileTemplateAddRef.current.showDrawer()} outline>Add</Button>
                ]}
            />

            <FileTemplateAddDrawer ref={FileTemplateAddRef} onAdd={refresh}/>

            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    icon={<HomeOutlined/>}
                    tab={"Container Payloads"}
                    >

                    <FileTemplateTable Sites={Templates !== undefined ? Templates.filter(x => x.Container == API.GetSetContainer()) : []} Loading={isLoading} onChange={refresh} />

                </Tabs.TabPane>

                <Tabs.TabPane
                    key="global"
                    icon={<GlobalOutlined />}
                    tab={"Shared Payloads"
                    }>

                    <FileTemplateTable Templates={Templates} Loading={isLoading} onChange={refresh} />

                </Tabs.TabPane>

            </Tabs>


        </>
    );

}
export default FileTemplatesPage